<template>
  <div class="guest-bg flex justify-center min-h-screen">
    <slot />
  </div>
</template>

<style>
.guest-bg {
  background: url('/guest-backgrounds/pexels-monkeytactics-18654646.jpg') no-repeat center center fixed;
  background-size: cover;
}
</style>